<template>
  <section class="d-flex w-100 flex-column align-items-left">
    <div
      class="rounded-button bg-blue-3 border-primary-light px-3 mx-2 justify-content-between"
      @click="$router.push('/dashboard')"
    >
      <span class="subtitle-text text-neutral-light font-weight-500">
        My Dashboard
      </span>
      <v-icon color="#fff" size="20px" class="ms-2"> mdi-cog </v-icon>
    </div>

    <div class="strong-header-text text-neutral mt-5 mb-3 px-3">
      Project Spaces
    </div>

    <side-bar-link
      :name="room.name"
      v-for="room in rooms"
      :key="room.id"
      :active="parseInt(room.id) === parseInt($route.params.id)"
      @click.native="switchRooms(room.id)"
    >
      <template v-slot:icon>
        <img
          :alt="room.typeName"
          width="35"
          :src="
            require(`../assets/icons/room-types/${room.typeName.replaceAll(
              ' ',
              '-'
            )}.png`)
          "
        />
      </template>
    </side-bar-link>

    <div
      class="rounded-button bg-blue-3 border-primary-light px-3 mt-5 mx-2 justify-content-between"
      @click.stop="showModal = true"
    >
      <span class="subtitle-text text-neutral-light font-weight-500">
        Add Space
      </span>
      <v-icon color="#fff" size="20px" class="ms-2"> mdi-door-open </v-icon>
    </div>

    <add-room-modal v-model="showModal" />
  </section>
</template>

<script>
import SideBarLink from "../components/SideBarLink.vue";
import AddRoomModal from "@/components/AddRoomModal.vue";

export default {
  name: "DefaultSidebar",
  components: {
    SideBarLink,
    AddRoomModal,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    rooms: function () {
      return this.$store.state.dashboard.rooms.filter(
        (r) => r.name !== "Project"
      );
    },
  },
  methods: {
    switchRooms(id) {
      this.$router.replace({ name: "Room", params: { id } });
    },
  },
};
</script>

<style></style>
